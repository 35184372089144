var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('app-total',{attrs:{"item-count":_vm.itemCount,"total-price":_vm.totalPrice}}),_c('table-body',_vm._b({attrs:{"is-column-settings":""},on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange,"settings-confirm":_vm.updateTableConfig}},'table-body',{
      currentPage: _vm.page,
      pageCount: _vm.pageCount,
      itemCount: _vm.itemCount,
      pageSize: _vm.page_size,
      fields: _vm.fields,
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('span',[_vm._v("Загрузка...")])],1)]},proxy:true},{key:"cell(status)",fn:function( ref ){
    var item = ref.item;
return [_c('b-button',{attrs:{"size":"md","variant":_vm.getVariant(item.status)}},[_vm._v(_vm._s(_vm.orderStatusCollection.find(function (status) { return status.value === item.status; }).text)+" ")])]}},{key:"cell(delivery_status)",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.deliveryTypeCollection.find(function (type) { return type.value === item.delivery_status; }).text)+" ")]}},{key:"cell(source)",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sources.find(function (i) { return i.id === item.source_id; }) ? _vm.sources.find(function (i) { return i.id === item.source_id; }).name : '')+" ")]}},{key:"cell(delivery_time)",fn:function(ref){
    var item = ref.item;
return [_c('p',[_vm._v(" с "+_vm._s(_vm.$dayjs(item.delivery_interval_from * 1000).format('DD MMM HH:mm'))+" ")]),_c('p',[_vm._v(" по "+_vm._s(_vm.$dayjs(item.delivery_interval_to * 1000).format('DD MMM HH:mm'))+" ")])]}},{key:"cell(client_phone)",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":("tel:+" + (item.client_phone))}},[_vm._v(_vm._s(_vm._f("VMask")(item.client_phone,'+#(###) ###-##-##')))])]}},{key:"cell(recipient_phone)",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":("tel:+" + (item.recipient_phone))}},[_vm._v(_vm._s(_vm._f("VMask")(item.recipient_phone,'+#(###) ###-##-##')))])]}},{key:"cell(composition_photo)",fn:function(ref){
    var item = ref.item;
return [(item.composition_photo.length)?[_vm._l((item.composition_photo),function(photo,index){return [_c('div',{key:index,class:index < item.composition_photo.length - 1 ? 'mb-1' : '',on:{"click":function($event){return _vm.onImage(Object.assign({}, item))}}},[_c('b-img',_vm._b({on:{"click":function($event){_vm.activeImgUrl = "" + _vm.url + (photo.photo)}}},'b-img',{
                  src: ("" + _vm.url + (photo.photo)),
                  alt: '',
                  width: '100px',
                },false))],1)]})]:_vm._e()]}},{key:"cell(pay_status)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.orderPaymentStatusCollection.find(function (status) { return status.value === item.pay_status; }) ? _vm.orderPaymentStatusCollection.find(function (status) { return status.value === item.pay_status; }).text : '')+" ")]}},{key:"cell(manager)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.manager_full_name)+" ")]}},{key:"cell(courier)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.courier_full_name)+" ")]}},{key:"cell(actions)",fn:function(ref){
                var item = ref.item;
return [_c('table-buttons',_vm._b({on:{"edit":function($event){return _vm.onEdit(Object.assign({}, item))},"delete":function($event){return _vm.onDelete(Object.assign({}, item))}}},'table-buttons',{
            disabled: _vm.userData.role === 'florist' || _vm.userData.role === 'counter',
          },false))]}}])},'b-table',{
        items: _vm.orders,
        fields: _vm.fields,
        striped: _vm.striped,
        hover: _vm.hover,
        responsive: _vm.responsive,
        emptyText: _vm.emptyText,
        showEmpty: _vm.showEmpty,
        perPage: _vm.page_size,
        busy: _vm.loading,
      },false))],1),_c('common-dialog',_vm._b({on:{"confirm":_vm.onConfirm}},'common-dialog',{
      title: _vm.modal.delete.visible
        ? _vm.modal.delete.title
        : _vm.modal.image.visible
          ? _vm.modal.image.title
          : _vm.modal.create.visible
           ? _vm.modal.create.title
           : _vm.modal.edit.title,
      value: _vm.modal.delete.visible ||
        _vm.modal.image.visible ||
        _vm.modal.edit.visible ||
        _vm.modal.create.visible,
      okOnly: _vm.modal.image.visible,
      okTitle: _vm.modal.delete.visible
        ? _vm.modal.delete.okTitle
        : _vm.modal.image.visible
          ? _vm.modal.image.okTitle
          : _vm.modal.edit.okTitle,
      hideHeader: !_vm.modal.create.visible && !_vm.modal.delete.visible,
      hideFooter: !_vm.modal.create.visible && !_vm.modal.delete.visible,
      size: _vm.modal.image.visible ? 'md' : '',
      modalClass: _vm.modal.image.visible ? 'app-modal-img' : '',
    },false),[(_vm.modal.delete.visible)?[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + _vm.order_id + "?"))+" ")])]:_vm._e(),(_vm.modal.image.visible)?[_c('div',[_c('img',{attrs:{"src":_vm.activeImgUrl}})])]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }