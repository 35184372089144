<template>
    <section>
        <receiver-card></receiver-card>
        <receiver-items></receiver-items>
    </section>
</template>

<script>
import ReceiverCard from './ReceiverCard.vue'
import ReceiverItems from './ReceiverItems.vue'

export default {
  name: 'ReceiverBaseItem',

  components: {
    ReceiverCard,
    ReceiverItems,
  },
}
</script>
