<template>
  <section>
    <app-total
      :item-count="itemCount"
      :total-price="totalPrice"
    ></app-total>
    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        itemCount,
        pageSize: page_size,
        fields,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
      @settings-confirm="updateTableConfig"
      is-column-settings
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: orders,
          fields,
          striped,
          hover,
          responsive,
          emptyText,
          showEmpty,
          perPage: page_size,
          busy: loading,
        }"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>

        <template #cell(status)=" { item } ">
          <b-button
            size="md"
            :variant="getVariant(item.status)"
          >{{ orderStatusCollection.find(status => status.value === item.status).text }}
          </b-button>
        </template>

        <template #cell(delivery_status)="{ item }">
          {{ deliveryTypeCollection.find(type => type.value === item.delivery_status).text }}
        </template>

        <template #cell(source)="{ item }">
          {{ sources.find(i => i.id === item.source_id)
            ? sources.find(i => i.id === item.source_id).name
            : '' }}
        </template>

        <template #cell(delivery_time)="{ item }">
          <p> с {{ $dayjs(item.delivery_interval_from * 1000).format('DD MMM HH:mm') }} </p>
          <p> по {{ $dayjs(item.delivery_interval_to * 1000).format('DD MMM HH:mm') }} </p>
        </template>

        <template #cell(client_phone)="{ item }">
          <a :href="`tel:+${item.client_phone}`">{{  item.client_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>
        <template #cell(recipient_phone)="{ item }">
          <a :href="`tel:+${item.recipient_phone}`">{{  item.recipient_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>

        <template #cell(composition_photo)="{ item }">
          <template v-if="item.composition_photo.length">
            <template v-for="(photo, index) in item.composition_photo">
              <div
                @click="onImage({ ...item })"
                :key="index"
                :class="index < item.composition_photo.length - 1 ? 'mb-1' : ''"
              >
                <b-img
                  v-bind="{
                    src: `${url}${photo.photo}`,
                    alt: '',
                    width: '100px',
                  }"
                  @click="activeImgUrl = `${url}${photo.photo}`"
                ></b-img>
              </div>
            </template>
          </template>
        </template>

        <template #cell(pay_status)="{ item }">
          {{ orderPaymentStatusCollection.find(status => status.value === item.pay_status)
            ? orderPaymentStatusCollection.find(status => status.value === item.pay_status).text
            : '' }}
        </template>

        <template #cell(manager)="{ item }">
          {{ item.manager_full_name }}
        </template>

        <template #cell(courier)="{ item }">
          {{ item.courier_full_name }}
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: userData.role === 'florist' || userData.role === 'counter',
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons>
        </template>
      </b-table>
    </table-body>

    <common-dialog
      v-bind="{
        title: modal.delete.visible
          ? modal.delete.title
          : modal.image.visible
            ? modal.image.title
            : modal.create.visible
             ? modal.create.title
             : modal.edit.title,
        value: modal.delete.visible ||
          modal.image.visible ||
          modal.edit.visible ||
          modal.create.visible,
        okOnly: modal.image.visible,
        okTitle: modal.delete.visible
          ? modal.delete.okTitle
          : modal.image.visible
            ? modal.image.okTitle
            : modal.edit.okTitle,
        hideHeader: !modal.create.visible && !modal.delete.visible,
        hideFooter: !modal.create.visible && !modal.delete.visible,
        size: modal.image.visible ? 'md' : '',
        modalClass: modal.image.visible ? 'app-modal-img' : '',
      }"
      @confirm="onConfirm"
    >
      <template v-if="modal.delete.visible">
        <p> {{ `${modal.delete.content} ${order_id}?`}} </p>
      </template>
      <template v-if="modal.image.visible">
        <div><img :src="activeImgUrl" /></div>
      </template>
    </common-dialog>
  </section>
</template>

<script>
import { AuthNameSpace } from '@/store/modules/auth/types'
import { mapState, mapActions, mapMutations } from 'vuex'
import { OrderNameSpace, OrderActionTypes } from '@/store/modules/order/types'
import { RecipientNameSpace, RecipientActionTypes } from '@/store/modules/recipient/types'
import { RootMutationTypes } from '@/store/types'
import { SourceNameSpace, SourceActionTypes } from '@/store/modules/source/types'
import { throttle } from 'lodash'
import { duration, BASE_URL } from '@/config'
import { BTable } from 'bootstrap-vue'

import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'
import AppTotal from '@/components/common/table/OrdersTotal.vue'

import { columns, modal } from '@/config/recipient_card'
import { deliveryTypeCollection, statusCollection, orderPaymentStatusCollection, orderStatusCollection } from '@/config/shared'

import { tableConfigSetup, tableConfigFormatBeforeSave } from '@/mixins/table-columns-controller'

export default {
  name: 'Orders',
  mixins: [tableConfigSetup, tableConfigFormatBeforeSave],
  components: {
    BTable,
    TableBody,
    TableButtons,
    AppTotal,
    CommonDialog: () => import('@/components/common/common-dialog'),
  },
  data() {
    return {
      order_id: '',
      id: '',
      recipient_id: this.$route.params.id,
      remove_completed_and_approved_filter: 1,
      source_id: '',
      status: '',
      date_from: '',
      date_to: '',
      page_size: 10,
      page: 1,
      imageUrl: '',
      time: false,
      columns: null,
      modal,
      loading: true,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
    url: {
      type: String,
      default() {
        return BASE_URL
      },
    },
  },
  computed: {
    ...mapState(['loaded']),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    ...mapState(OrderNameSpace, {
      orders: 'orders',
      order: 'order',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
      totalPrice: 'totalPrice',
    }),
    ...mapState(RecipientNameSpace, {
      config: 'config',
    }),
    ...mapState(SourceNameSpace, {
      sources: 'sources',
    }),

    fields() {
      return this.tableConfigSetup({ local_config: columns, response_config: this.config })
    },

    orderSources() {
      const result = this.sources.map(item => {
        return { text: item.name, value: item.id }
      })
      result.unshift({ text: 'Источники заказа', value: '' })
      return result
    },

    statusCollection() {
      return statusCollection
    },

    orderPaymentStatusCollection() {
      return orderPaymentStatusCollection
    },

    orderStatusCollection() {
      return orderStatusCollection
    },
    deliveryTypeCollection() {
      return deliveryTypeCollection
    },
  },

  async created() {
    await this.loadTableConfig()
    await this.loadOrderCollection()
    await this.loadSourceCollection()
  },

  methods: {
    ...mapMutations({
      [RootMutationTypes.SetLoadedState]: RootMutationTypes.SetLoadedState,
    }),
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.DeleteOrder]: OrderActionTypes.DeleteOrder,
      [OrderActionTypes.LoadOrder]: OrderActionTypes.LoadOrder,
      [OrderActionTypes.LoadOrderCollection]: OrderActionTypes.LoadOrderCollection,
    }),
    ...mapActions(RecipientNameSpace, {
      [RecipientActionTypes.LoadRecipientTableConfig]: RecipientActionTypes.LoadRecipientTableConfig,
      [RecipientActionTypes.UpdateRecipientTableConfig]: RecipientActionTypes.UpdateRecipientTableConfig,
    }),
    ...mapActions(SourceNameSpace, {
      [SourceActionTypes.LoadSourceCollection]: SourceActionTypes.LoadSourceCollection,
    }),

    load: throttle(async function({ id }) {
      await this[OrderActionTypes.LoadOrder]({ id })
    }, duration),

    loadOrderCollection: throttle(async function() {
      try {
        this.loading = true
        await this[OrderActionTypes.LoadOrderCollection]({ ...this.$data })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }, duration),

    async loadTableConfig() {
      await this[RecipientActionTypes.LoadRecipientTableConfig]()
    },

    async loadSourceCollection() {
      await this[SourceActionTypes.LoadSourceCollection]({ page: 1, page_size: 100 })
    },

    async updateTableConfig({ settings }) {
      await this[RecipientActionTypes.UpdateRecipientTableConfig]({ settings: this.tableConfigFormatBeforeSave({ settings }) })
    },

    onEdit({ id }) {
      this.$router.push(`/order-base/${id}`)
    },

    onDelete({ id }) {
      this.order_id = id

      this.modal.delete.visible = true
    },

    onImage(img) {
      this.imageUrl = img

      this.modal.image.visible = true
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.delete.visible) {
            await this[OrderActionTypes.DeleteOrder]({ id: this.order_id })

            if (this.orders.length === 1 && this.page !== 1) this.page -= 1

            this[RootMutationTypes.SetLoadedState]({ loaded: true })

            await this.loadOrderCollection({ ...this.$route.params })

            this[RootMutationTypes.SetLoadedState]({ loaded: false })
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    getVariant(status) {
      const statusCollection = {
        0: 'light',
        1: 'success',
        2: 'info',
        3: 'warning',
        4: 'primary',
      }
      return statusCollection[status]
    },

    onSettings({ fields }) {
      this.tableColumns = fields
    },

    async onResetFilter() {
      this.source_id = null
      this.status = null
      await this.loadOrderCollection()
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)
      await this.loadOrderCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)
      await this.loadOrderCollection()
    },
  },
}
</script>

<style scoped>
</style>
