<template>
  <app-card
    @admit="onEdit({ ...$route.params })"
    @decline="onCancel"
  >
    <template #body>
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 mb-1 mt-1">
            <span>ID: {{ $route.params.id }}</span>
          </div>
          <div class="col-12 col-lg-6 mb-1 mt-0 mt-lg-1">
            <span>Дата регистрации: {{ created_at }}</span>
          </div>
          <div class="col-12 col-lg-6">
            <label for="staff-name">ФИО</label>
            <b-form-input
              id="staff-name"
              v-model="form.full_name"
              class="mb-1"
              :state="validation.full_name"
              @change="onValidate({ ...$data, property: 'full_name' })"
            ></b-form-input>
          </div>
          <div class="col-12 col-lg-6">
            <label for="staff-phone">Телефон</label>
            <b-form-input
              ref="phone"
              id="staff-phone"
              v-mask="phoneMask"
              v-model="form.phone"
              class="mb-1"
              :state="validation.phone"
              @change="onValidate({ ...$data, property: 'phone' })"
            ></b-form-input>
          </div>
        </div>
      </div>
    </template>
  </app-card>
</template>

<script>
import Card from '@/components/common/pages/Card';
import { mapState, mapActions } from 'vuex'
import { RecipientNameSpace, RecipientActionTypes } from '@/store/modules/recipient/types';
import { baseValidation } from '@/utils/validation';
import { throttle } from 'lodash';
import { duration, phoneMask } from '@/config';
import { phoneToMaskView } from '@/utils/phone-format';

export default {
  name: 'RecipientCard',
  components: {
    'app-card': Card,
  },
  data() {
    return {
      id: '',
      created_at: '',
      order_id: '',
      form: {
        full_name: '',
        phone: '',
        city_id: '',
      },
      phoneMask,
      validation: {
        full_name: null,
        phone: null,
      },
    }
  },
  computed: {
    ...mapState(RecipientNameSpace, {
      recipient: 'recipient',
    }),
    phoneSerialized() {
      return this.form.phone.replace(/\D/g, '')
    },
  },
  async created() {
    await this.editInit({ ...this.$route.params })
  },
  methods: {
    ...mapActions(RecipientNameSpace, {
      [RecipientActionTypes.LoadRecipient]: RecipientActionTypes.LoadRecipient,
      [RecipientActionTypes.UpdateRecipient]: RecipientActionTypes.UpdateRecipient,
    }),

    loadRecipient: throttle(async function({ id }) {
      await this[RecipientActionTypes.LoadRecipient]({ id })
    }, duration),

    async onEdit() {
      if (this.onValidate({ ...this.$data })) {
        await this[RecipientActionTypes.UpdateRecipient]({ ...this.form, phone: this.phoneSerialized })
      }
    },
    async editInit({ id }) {
      await this.loadRecipient({ id })
      const { full_name, phone, city_id } = this.recipient
      this.form = { id, full_name, phone: this.phoneToMaskView({ phone: phone }), city_id }
      this.created_at = this.$dayjs(this.recipient.created_at * 1000).format('DD.MM.YYYY')
    },
    onCancel() {
      this.$router.push('/receivers')
    },
    onValidate({ validation, form, property }) {
      const result = baseValidation({ validation, form, property })
      return result
    },
    phoneToMaskView({ phone }) {
      return phoneToMaskView({ phone })
    },
  },
};
</script>
