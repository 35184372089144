const columns = Object.freeze([
  {
    key: 'status',
    label: 'Статус',
    sortable: false,
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'source',
    label: 'Источник',
    sortable: false,
    status: '',
  },
  {
    key: 'number',
    label: 'Номер заказа',
    status: '',
  },
  {
    key: 'delivery_status',
    label: 'Тип доставки',
    status: '',
  },
  {
    key: 'delivery_time',
    label: 'Время доставки/самовывоза',
    status: '',
  },
  {
    key: 'number_daily',
    label: '№',
    status: '',
  },
  {
    key: 'delivery_address',
    label: 'Адрес доставки',
    status: '',
  },
  {
    key: 'delivery_comment',
    label: 'Коментарий к адресу',
    status: '',
  },
  {
    key: 'delivery_service',
    label: 'Курьерская служка',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'client_full_name',
    label: 'ФИО заказчика',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'client_phone',
    label: 'Номер заказчика',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'recipient_full_name',
    label: 'ФИО получателя',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'recipient_phone',
    label: 'Номер получателя',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'postcard_text',
    label: 'Текст открытки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'composition_photo',
    name: 'composition_photo',
    label: 'Фото композиции',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'composition_comment',
    name: 'composition_comment',
    label: 'Коментарий к композиции',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'total_price',
    label: 'Сумма к оплате',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_price',
    label: 'Стоимость доставки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'pay_status',
    label: 'Статус оплаты',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'manager',
    label: 'Ответственный менеджер',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'courier',
    label: 'Назначенный курьер',
    status: '',
    thClass: '',
    tdClass: '',
  },
  // {
  //   key: 'composition_photo',
  //   name: 'composition_photo',
  //   label: 'Фото композиции',
  //   hidden: true,
  //   status: '',
  //   thClass: '',
  //   tdClass: '',
  // },
  {
    key: 'order_proceeds',
    name: 'order_proceeds',
    label: 'Выручка с заказа',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'actions',
    name: 'actions',
    label: '',
    hidden: true,
    status: '',
    thClass: '',
    tdClass: '',
  },
])

const actions = Object.freeze([
  {
    key: 'edit',
    variant: 'primary',
    icon: 'EditIcon',
    to: { name: 'order-base-item', params: ['id'] },
  },
])

const modal = {
  delete: {
    title: 'Вы действительно хотите удалить?',
    content: 'Вы действительно хотите удалить',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото композиции',
    visible: false,
    okTitle: 'Закрыть',
  },
  edit: {
    title: 'Изменить',
    visible: false,
    okTitle: 'Изменить',
  },
  create: {
    title: 'Создать Расход',
    visible: false,
    okTitle: 'Создать',
  },
}

export { columns, actions, modal }
